@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Josefin+Sans&family=Montserrat:wght@400;500;600;700;800&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* * {
  border: 1px solid red;
} */

.x-modal .modal-content {
  margin: 0px !important;
  position: static !important;
  margin: 0px auto !important;
}

.inner-content .title {
  text-align: center !important;
}

/* .x-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} */
@media (max-width: 767px) {
  .banner_area {
    min-height: 80vh !important;
    height: unset !important;
  }

  .banner_area .banner__content {
    top: unset !important;
  }

  .chepi-play {
    width: 90px !important;
  }
  .chepi-video_box {
    position: absolute !important;
    bottom: 72% !important;
    right: 0%;
  }
}

@media (min-width: 767px) {
  .contact_sec .content_area select {
    padding-left: 10px;
  }
  /* .chepi-video_box {
    position: absolute !important;
    bottom: 50% !important;
    right: 0%;
  } */
}

@media (max-width: 767px) {
  #adjust-b {
    margin: 50px 0px !important;
  }
}

.carousel__viewport {
  visibility: hidden !important;
}

.list_title {
  text-align: center;
}
