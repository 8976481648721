body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  background-color: transparent;
  outline: transparent;
  border-color: transparent;
  border-style: hidden;
}

button:focus {
  outline: none;
}

.Mui-focused {
  color: white !important;
}

.MuiInput-underline::after {
  color: white !important;
  border-color: white !important;
  border-bottom: 1px dotted white !important;
}
