.mint-container {
  /* position: absolute;
  top: 45%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  width: 24rem;
  /* max-width: 90%; */
  height: 20rem;
  background-color: #062040;
  border-radius: 5%;
  padding: 1rem;
  color: white;
}
.mint-container button.pbu svg {
  height: 1.4em;
  width: 1.4em;
}
.mint-container h3 {
  /* font-family: "Joyride-STD"; */
  font-size: 1rem;
  text-transform: uppercase;
}
.mint-container .my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.mint-container .Mui-disabled {
  -webkit-text-fill-color: #fff !important;
}
.mint-container .stroke {
  margin: 0;
  min-height: 6px;
}
.mint-container .mint-box {
  height: 18rem;
}
.mint-container .Mui-disabled {
  color: white;
  -webkit-text-fill-color: white;
  font-family: "Joyride-STD";
  font-size: 1rem;
}
.mint-container .Mui-disabled::before {
  outline-color: white !important;
  border-color: white !important;
}
.mint-container input.Mui-disabled {
  font-family: "OpenSans";
}
.MuiBackdrop-root {
  backdrop-filter: blur(5px);
}
